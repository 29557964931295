<template>
  <div class="row mt-5">
    <div class="col-xs-12 col-md-6 offset-md-3">
      <div class="card mt-5 nw-card">
        <div class="card-body text-left">
          <div class="text-center h2 nw-dark-blue">Multi-factor Authentication Setup</div>
          <div>Users with administrative access to the Admin Portal are required to set up two-factor authentication using a one-time
            password application on your mobile device. Good options are Authy or Google Authenticator. Please use the app to scan this QR code
            to start the setup process. You will need to enter the one-time password it generates to complete the setup process.</div>
            <div class="text-center">
              <img :src="qrurl" alt="QR Code Failed"/>
            </div>
            <div class="col-6 offset-3">
              <div class="form-group">
                <label class="control-label">One-time Password</label>
                <input class="form-control" type="text" v-model="mfaCode"/>
              </div>
            </div>
            <div class="text-center mb-2">
                <button class="btn nw-btn-info text-uppercase" @click="setup()" :disabled="mfaCode.length != 6">Complete Setup</button>
            </div>
            <div class="col-xs-10" v-show="nc.resp.length > 0">
              <div class="alert alert-danger" role="alert">
                <div v-for="(item,idx) in nc.resp" :key="idx" :title="item.msg">
                  {{ item.msg }}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  computed: {
    qrurl () {
      return process.env.VUE_APP_HOST_NAME + 'rest/qrcode?uid=' + NetClient.ctx.uid + '&token=' + NetClient.ctx.token
    }
  },
  data () {
    return {
      nc: NetClient,
      mfaCode: ''
    }
  },
  methods: {
    setup () {
      NetClient.resp = []
      NetClient.retval = 'OK'
      NetClient.doRequest('rest/user',{action: 'setupMFA', mfaCode: this.mfaCode}).then(msg => {
        if(msg.retval == 'OK') this.$router.push({ name: 'Login'})
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
